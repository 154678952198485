import "../styles/globals.css";
import { ThemeProvider } from "next-themes";
import Layout from "../components/layout";
import { Provider } from "react-redux";
import { store } from "../redux/store";
import { useRouter } from "next/router";
import { MetaMaskProvider } from "metamask-react";
import Meta from "../components/Meta";
import UserContext from "../components/UserContext";
import NextNProgress from 'nextjs-progressbar';
import { useRef } from "react";
import { SessionProvider } from "next-auth/react";
import Script from 'next/script'

import "../styles/theme/vendors/fontawesome-pro-5/css/all.css"
import "primereact/resources/themes/vela-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

function MyApp({ Component, pageProps, session }) {
  const router = useRouter();
  const pid = router.asPath;
  const scrollRef = useRef({
    scrollPos: 0,
  });

  return (
    <>
      <Script strategy="afterInteractive" src="https://www.googletagmanager.com/gtag/js?id=G-KNVWRBXC67"></Script>
      <Script 
        id='linkedin-analytics'
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          type="text/javascript";
          _linkedin_partner_id = "5844124";
          window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
          window._linkedin_data_partner_ids.push(_linkedin_partner_id);
          (function(l) {
          if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
          window.lintrk.q=[]}
          var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);})(window.lintrk);`
        }} 
      />
      <Script
        id='google-analytics'
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-KNVWRBXC67', {
            page_path: window.location.pathname,
          });
        `,
        }}
      />
      <script dangerouslySetInnerHTML={{ __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','GTM-NNPQP4S3');
          ` }} />
      <NextNProgress color="hsl(264deg 100% 58%)" startPosition={0.3} stopDelayMs={200} height={3} showOnShallow={true} />
      {/* <Meta title="Davinte.AI - A prioneed AI platform for interior designers" /> */}
      <SessionProvider session={session}>
        <Provider store={store}>
          <ThemeProvider enableSystem={true} attribute="class">
            <MetaMaskProvider>
              <UserContext.Provider value={{ scrollRef: scrollRef }}>
                {pid === "/login" ? (
                  <Component {...pageProps} />
                ) : (
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                )}
              </UserContext.Provider>
            </MetaMaskProvider>
          </ThemeProvider>
        </Provider>
      </SessionProvider>
    </>
  );
}

export default MyApp;
