import React, { useState, useEffect } from "react";
import PlanList from "./planList";
import { setPlansData, plansModalShow, plansModalHide, } from "../../redux/counterSlice";
import { useSelector, useDispatch } from "react-redux";
import Modal from '@mui/material/Modal';
import {URLs} from "../../utils/enums";
import ModalWrapper from '../modal/modalWrapper';

export default function PlanModalWrapper() {
    const { plansModal, mustSubscribe } = useSelector((state) => state.counter);
    const dispatch = useDispatch();

    useEffect(() => {
        async function loadPlans() {
            const res = await fetch(URLs.GET_ALL_PLANS);
            const data = await res.json();
            // console.log("plans data", data);
            dispatch(setPlansData(data));
        }
        loadPlans();
    }, []);

    function onClose() {
        dispatch(plansModalHide());
    }

    return (
        <ModalWrapper modalOpen={plansModal} setModalOpen={onClose} screen_width={"w-11/12 md:max-w-7xl"}>
            <PlanList />
        </ModalWrapper>

        // <Modal
        //     open={plansModal}
        //     onClose={() => dispatch(plansModalHide())}
        //     disableEscapeKeyDown={mustSubscribe}
        //     aria-labelledby="modal-modal-title"
        //     aria-describedby="modal-modal-description">
        //     <div className="modal fade show block">
        //         <div
        //             className="dark:border-jacarta-600 p-3 border-jacarta-100 bg-jacarta-50 dark:bg-jacarta-800 rounded-lg z-10 w-5/6 sm:w-4/6 md:w-11/12 max-h-screen lg:max-w-5xl xl:max-w-6xl overflow-auto"
        //             style={{
        //                 position: "fixed",
        //                 top: "50%",
        //                 left: "50%",
        //                 transform: "translate(-50%, -50%)",
        //                 zIndex: "999",
        //             }}>
        //             <div className="md:inset-y-0 flex flex-col h-full">
        //                 {!mustSubscribe && (<div className="h-7">
        //                     <button
        //                         type="button"
        //                         className="btn-close mr-2"
        //                         style={{ right: "0.75rem" }}
        //                         onClick={() => dispatch(plansModalHide())}>
        //                         <svg
        //                             xmlns="http://www.w3.org/2000/svg"
        //                             viewBox="0 0 24 24"
        //                             width="24"
        //                             height="24"
        //                             className="fill-jacarta-700 h-6 w-6 dark:fill-white">
        //                             <path fill="none" d="M0 0h24v24H0z" />
        //                             <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
        //                         </svg>
        //                     </button>
        //                 </div>)}
        //                 <div className="flex h-full flex-col justify-center">
        //                     <div className="scrollbar-trigger flex h-full w-full flex-1 items-start border-white/20">
        //                         <div className="flex h-full flex-1 flex-col space-y-1 p-1">
        //                             <PlanList />
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </div>
        // </Modal>
    )
}