import { useEffect, useState } from "react";
import Link from "next/link";
import { useSelector, useDispatch } from "react-redux";
import { SubscriptionStatus } from "../../utils/enums_backend";
import { useSession, signIn, signOut } from "next-auth/react";
import Modal from "@mui/material/Modal";
import { plansModalShow, renewSubModalHide } from "../../redux/counterSlice";

export default function AskToRenew(props) {
    const dispatch = useDispatch();
    const { subStatus, renewSubModal } = useSelector((state) => state.counter);
    // console.log("renewSubModal", renewSubModal);
    return (
        <Modal
            open={renewSubModal}
            onClose={() => dispatch(renewSubModalHide())}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <div className="modal fade show block">
                <div className="modal-dialog" style={{ minWidth: "350px", maxWidth: "600px", width: "100%" }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Manage Subscription
                            </h5>
                            <button
                                type="button"
                                className="btn-close"
                                onClick={() => dispatch(renewSubModalHide())}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                    className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                                >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                                </svg>
                            </button>
                        </div>
                        <div className="modal-body p-6 flex flex-col">
                            {subStatus && (
                                <>
                                    <span className="text-base my-2">Your current subscription is <span className={
                                        (subStatus.status == SubscriptionStatus.ACTIVE
                                            ? "text-green"
                                            : "text-red") + " ml-2"
                                    }>
                                        {subStatus.status.replace("_", " ")}
                                    </span></span>
                                    <span className="text-base my-2">Please register or renew a plan</span>
                                    <span className="js-copy-clipboard text-sm text-jacarta-700 my-4 flex select-none items-center whitespace-nowrap leading-none dark:text-white">
                                        <div className="w-full">
                                            <span
                                                onClick={() => {
                                                    dispatch(plansModalShow({
                                                        mustSubscribe: false,
                                                        noTrail: (subStatus) ? true : false
                                                    }))
                                                    dispatch(renewSubModalHide())
                                                }}
                                                className="cursor-pointer dark:border-jacarta-600 border-white dark:bg-accent text-md bg-accent text-white flex w-full items-center justify-center border-2 px-8 text-center font-semibold transition-all hover:border-transparent hover:dark:border-transparent  dark:text-white rounded-md py-2">
                                                Click to subscribe a Plan
                                            </span>
                                        </div>
                                    </span>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}