import React from 'react';
import Modal from '@mui/material/Modal';

export default function ModalWrapper({ modalOpen, setModalOpen, children, screen_width = "w-11/12 sm:w-10/12 md:w-9/12 lg:max-w-4xl xl:max-w-5xl", mustDisplay = false, title = '' }) {

    return (
        <Modal
            open={modalOpen}
            onClose={() => setModalOpen(false)}
            disableEscapeKeyDown={mustDisplay}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <div className="modal fade show block">
                <div
                    className={`dark:border-jacarta-600 md:p-3 p-1 border-jacarta-100 bg-jacarta-50 dark:bg-jacarta-800 rounded-lg z-10 max-h-screen ${screen_width} overflow-auto`}
                // style={{
                //     position: "fixed",
                //     top: "50%",
                //     left: "50%",
                //     transform: "translate(-50%, -50%)",
                //     zIndex: "999",
                // }}
                >
                    <div className="flex flex-col h-full w-full">
                        <div className="h-7 flex justify-between relative mt-10 mb-5">
                            <h4 className="text-lg font-bold pb-5">
                                {title}
                            </h4>
                            {!mustDisplay && (<button
                                type="button"
                                className="btn-close right-2"
                                // style={{ right: "0.75rem" }}
                                onClick={() => setModalOpen(false)}>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                    className="fill-jacarta-700 h-6 w-6 dark:fill-white">
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                                </svg>
                            </button>)}
                        </div>
                        <div className="flex h-full flex-col w-full justify-center">
                            <div className="scrollbar-trigger flex h-full w-full flex-1 items-start border-white/20">
                                <div className="flex h-full w-full  flex-1 flex-col space-y-1 p-1">
                                    {
                                        children
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}