import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { termsShow, termsHide } from "../../redux/counterSlice";
import ModalWrapper from '../modal/modalWrapper';
import { useForm } from "react-hook-form";
import { URLs, ErrorCodes } from '../../utils/enums'
import Link from "next/link";
import { useRouter } from 'next/router';
import toast from "react-hot-toast";
import { useSession } from "next-auth/react";

async function fetchTerms() {
    const res = await fetch(URLs.GET_TERMS_STATUS);
    const data = await res.json();
    return data;
}

export default function TermsPolicies(props) {
    const { termsModal } = useSelector((state) => state.counter);
    const dispatch = useDispatch();
    const router = useRouter();
    const { data: session } = useSession();
    const [loading, setLoading] = useState(false);
    const form = useRef(null);

    const {
        register,
        handleSubmit,
        formState: { errors },
        control,
        setValue,
        clearErrors
    } = useForm({
        defaultValues: {
            image: null
        },
    });

    async function onSubmit() {
        setLoading(true);
        const res = await fetch(URLs.ACCEPT_TERMS, {
            method: "POST",
        }).then(async (res) => {
            setLoading(false);
            const res_json = await res.json();

            if (res.status == ErrorCodes.SUCCESS_ADD) {
                toast.success(`Successfully accepted the terms. Have fun with Davinte.AI!`);
                dispatch(termsHide());
                return;
            }
            else {
                toast.error('Something went wrong. Please try again.');
            }
        });
    }
    
    const pid = router.asPath;
    useEffect(() => {
        if (!session) {
            return;
        }

        async function fetchTermsInner() {
            const data = await fetchTerms();
            const { termsAccepted } = data;
            
            if (termsAccepted == false && (pid != '/terms' && pid != '/privacy' && pid != '/checkout/Trial')) {
                dispatch(termsShow());
            }
            else {
                dispatch(termsHide());
            }
        }

        fetchTermsInner();
        
    }, [pid, session])

    return (
        <ModalWrapper mustDisplay={true} modalOpen={termsModal} setModalOpen={null} screen_width={"max-w-lg w-full overflow-auto"}>
            <form ref={form} onSubmit={handleSubmit(onSubmit)} className="flex flex-col justify-center items-start">
                <h4 className="text-xl font-bold pb-5">Accept Terms and Privacy Policies</h4>
                <div className="w-full px-5">
                    <p className="pb-2 pt-5">Your <a href="/checkout/Trial" target="_blank" rel="noopener noreferrer" className="text-accent-light font-bold">3 day trial</a> stars from today.</p>
                    <p className="pb-2 text-sm text-jacarta-600 dark:text-jacarta-300">
                        To learn more about your <Link target="_blank" href="/checkout/Trial" rel="noopener noreferrer" className="text-accent-light font-bold">Trial Plan</Link>, please visit <Link target="_blank" href="/checkout/Trial" rel="noopener noreferrer" className="text-accent-light font-bold">this page</Link>
                    </p>
                    <p className="my-5">
                        Please accept the terms and privacy policies to start using the platform
                    </p>
                </div>
                <div className="flex flex-col justify-center items-start px-5 pb-5">
                    <div className="my-2">
                        <input {...register('accept_terms', { required: true })} type="checkbox" id="accept_terms" className="focus:ring-accent-light h-4 w-4 text-accent border-gray-400 rounded" />
                        <label className="pl-2" htmlFor="accept_terms">I accept the <Link href="/terms" className="underline text-accent">Terms and Conditions</Link></label>
                        {errors.accept_terms && <span className="text-red ml-1">*</span>}
                    </div>
                    <div className="my-2">
                        <input {...register('accept_privacy', { required: true })} type="checkbox" id="accept_privacy" className="focus:ring-accent-light h-4 w-4 text-accent border-gray-400 rounded" />
                        <label className="pl-2" htmlFor="accept_privacy">I accept the <Link href="/privacy" className="underline text-accent">Privacy Policy</Link></label>
                        {errors.accept_privacy && <span className="text-red ml-1">*</span>}
                    </div>
                </div>
                <button className="button-main mb-5" disabled={loading} type="submit">{loading? "Submitting the request ..." : "Accept"}</button>
            </form>
        </ModalWrapper>
    )
}