const crypto = require("crypto");
import { SubInterval } from "./enums_backend";

export function getMinutesBetweenDates(date1, date2) {
  const diffInMs = Math.abs(date2 - date1); // Get the difference in milliseconds
  const diffInMinutes = Math.floor(diffInMs / (1000 * 60)); // Get the difference in minutes

  return diffInMinutes;
}

export function getDaysBetweenDates(todayDate, endDate) {
  let difference = endDate.getTime() - todayDate.getTime();
  let TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
  return TotalDays;
}

export function currencyFormat(num) {
  if (!num) return "---";

  const val = parseFloat(num.toString())
    .toFixed(0)
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  return val;
}

export function generateUniqueSharePath() {
  const randomBytes = crypto.randomBytes(12); // Generate 12 random bytes
  const randomString = randomBytes.toString("hex"); // Convert the random bytes to a hexadecimal string

  return randomString;
}

export function setEndDate(interval, intervalCount) {
  if (interval == SubInterval.DAILY) {
    return new Date(new Date().setDate(new Date().getDate() + intervalCount));
  } else if (interval == SubInterval.MONTHLY) {
    return new Date(new Date().setMonth(new Date().getMonth() + intervalCount));
  } else if (interval == SubInterval.ANNUALLY) {
    return new Date(new Date().setMonth(new Date().getMonth() + 12));
  } else {
    return new Date(new Date().setDate(new Date().getDate() + 7));
  }
}

export function selectRandomElements(arr, topN) {
  const result = [];
  const n = arr.length;
  for (let i = 0; i < topN; i++) {
    const randomIndex = Math.floor(Math.random() * n);
    result.push(arr[randomIndex]);
  }
  return result;
}

export function extractFileName(url) {
  try {
    // Create a URL object from the given URL string
    const urlObj = new URL(url);

    // Extract the pathname from the URL
    const pathname = urlObj.pathname;

    // Get the last part of the pathname as the file name
    const fileName = pathname.split("/").pop();

    return fileName;
  } catch (error) {
    console.error("Error extracting file name:", error);
    return null;
  }
}

function isImageTemplateValue(url) {
  try {
    // Create a URL object from the given URL string
    const urlObj = new URL(url);

    // Check if the hostname of the URL matches 'davinte.ai'
    return urlObj.hostname === "davinte.nyc3.cdn.digitaloceanspaces.com";
  } catch (error) {
    console.error("Invalid URL:", error);
    return false;
  }
}

export const defaultBlurData = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAADCAIAAAA7ljmRAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAMklEQVQImQEnANj/AIqw1LbO6qW61K3O6gDy6+H/+tCSclOOg3cAaE1Ho3daLxUBIQAA6egUUfcotbUAAAAASUVORK5CYII="

