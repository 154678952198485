import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { loginModalHide } from "../../redux/counterSlice";
import LoginBody from "./login_body";
import Modal from '@mui/material/Modal';

export default function LoginModalWrapper() {
    const { loginModal, mustLogin } = useSelector((state) => state.counter);
    const dispatch = useDispatch();
    // console.log('loginModal', loginModal);
    return (
        <Modal
            open={loginModal}
            onClose={() => dispatch(loginModalHide())}
            disableEscapeKeyDown={mustLogin}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <div className="modal fade show block">
                <div className="modal-dialog" style={{ minWidth: '350px', maxWidth: '410px' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">
                                Sign in / Register
                            </h5>
                            {!mustLogin && (<button
                                type="button"
                                className="btn-close"
                                onClick={() => dispatch(loginModalHide())}
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 24 24"
                                    width="24"
                                    height="24"
                                    className="fill-jacarta-700 h-6 w-6 dark:fill-white"
                                >
                                    <path fill="none" d="M0 0h24v24H0z" />
                                    <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
                                </svg>
                            </button>)}
                        </div>
                        <div className="modal-body p-6">
                            <LoginBody />
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}