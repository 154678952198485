import Head from 'next/head'

const Favicon = () => {
    return (
        <Head>
            <link rel="apple-touch-icon" sizes="180x180" href="/images/favicons/apple-touch-icon.png"/>
            <link rel="icon" type="image/png" sizes="32x32" href="/images/favicons/favicon-32x32.png"/>
            <link rel="icon" type="image/png" sizes="16x16" href="/images/favicons/favicon-16x16.png"/>
            <link rel="manifest" href="/images/favicons/site.webmanifest"/>
            {/* <link rel="mask-icon" href="/images/favicons/safari-pinned-tab.svg" color="#5bbad5"/> */}
            <meta name="apple-mobile-web-app-title" content="Snippit"/>
            <meta name="application-name" content="Davinte.AI"/>
            <meta name="msapplication-TileColor" content="#8358FF"/>
            <meta name="theme-color" content="#ffffff"/>
        </Head>
    );
}

export default Favicon;
