import { configureStore } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice';
import counterReducer from './counterSlice';
import exploreReducer from './exploreDesigns';

export const store = configureStore({
	reducer: {
		counter: counterReducer,
		explore: exploreReducer,
	},
});
