import Footer from "./footer";
import { useRouter } from "next/router";
import Header01 from "./header/Header01";
import LoginModalWrapper from "../components/login/loginModalWrapper";
import PlanModalWrapper from "../components/plans/planModalWrapper";
import AskToRenew from "../components/subscription/askToRenew";
import { Toaster } from "react-hot-toast";
import TermsPolicies from '../components/terms/termsAccept'

export default function Layout({ children }) {
  const route = useRouter();
  const pid = route.asPath;
  // header start
  let header = <Header01 />;
  // header end

  return (
    <>
      {header}
      <LoginModalWrapper />
      <PlanModalWrapper />
      <AskToRenew />
      <TermsPolicies />
      <Toaster
        containerStyle={{
          position: 'fixed',
          top: 80,
          left: 20,
          bottom: 20,
          right: 20,
        }}
        reverseOrder={true} />
      <main>{children}</main>
      {pid != "/ideas" && <Footer />}
    </>
  );
}
