import { IdeasStatus, DesignVisibility } from "./enums";

export const subscription = {
    id: true,
    stripeSubscriptionId: true,
    status: true,
    startDate: true,
    endDate: true,
    renewalDate: true,
    createdAt: true,
    budgetInitial: true,
    budgetRemaining: true,
    amountPaid: true,
    currency: true,
    recurring: true,
    plan: {
        select: {
            name: true,
            price: true,
            rank: true,
            isPrivateSupported: true,
            supportedModels: true,
            metadata: true,
        }
    }
}

export const designsExplore = (userId) => {
    let ideaClause = {
        id: true,
        designID: true,
        userId: true,
        imagePath: true,
        imageWidth: true,
        imageHeight: true,
        imageSize: true,
        createdAt: true,
        type: true,
        likes: {
            where: {
                isDeleted: { equals: false },
                userId: { equals: userId }
            },
            select: {
                id: true,
                userId: true,
                ideaID: true,
                createdAt: true
            }
        }
    }

    let ideaWhereClause = {
        isDeleted: false,
    };

    if (!userId) {
        ideaClause = {
            id: true,
            designID: true,
            userId: true,
            imagePath: true,
            imageWidth: true,
            imageHeight: true,
            imageSize: true,
            createdAt: true,
            type: true,
        }
    }

    return {
        targetCdnImageUrl: true,
        imageIdeaPaths: true,
        createdAt: true,
        styleName: true,
        roomTypeName: true,
        modelName: true,
        modelId: true,
        buildingTypeId: true,
        buildingTypeName: true,
        extStyleId: true,
        extStyleName: true,
        spaceType: true,
        targetImageWidth: true,
        num_images_per_prompt: true,
        targetImageHeight: true,
        timeToDesign: true,
        visibilityName: true,
        isDavintePrompt: true,
        strength: true,
        userPrompt: true,
        prompt: true,
        otherConfig: true,
        interfaceConfig: true,
        magicEditConfig: true,
        subId: true,
        status: true,
        id: true,
        user: {
            select: {
                userName: true,
                image: true,
                id: true,
            }
        },
        parent: {
            select: {
                id: true,
                imageIdeaPaths: true,
                targetCdnImageUrl: true
            }
        },
        ideas: {
            where: ideaWhereClause,
            select: ideaClause
        }
    }
}


export function designWhereClause(isFav = 0, userId, explore = false) {
    let whereClause = {
        userId: userId,
        isDeleted: false
    }

    if (explore) {
        whereClause = {
            // visibilityName: (isFav == 0)? DesignVisibility.PUBLIC : ,
            status: IdeasStatus.COMPLETED,
            isDeleted: false
        }

        if (isFav == 0) {
            whereClause["visibilityName"] = DesignVisibility.PUBLIC;
        }        
    }

    if (isFav == 1) {
        whereClause = {
            userId: userId,
            isDeleted: false,
            status: IdeasStatus.COMPLETED,
            ideas: {
                some: {
                    likes: {
                        some: {
                            isDeleted: { equals: false },
                            userId: { equals: userId }
                        }
                    }
                }
            }
        }
    }

    if (isFav == 1 && explore) {
        whereClause = {
            isDeleted: false,
            // visibilityName: DesignVisibility.PUBLIC,
            status: IdeasStatus.COMPLETED,
            ideas: {
                some: {
                    likes: {
                        some: {
                            isDeleted: { equals: false },
                            userId: { equals: userId }
                        }
                    }
                }
            }
        }
    }

    return whereClause;
}
