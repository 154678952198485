import Image from "next/image";
import Link from "next/link";
import DarkMode from "../mode/DarkMode";
import Logo from "./../../public/images/logo/Davinte_black_new.png";
import WhiteLogo from "./../../public/images/logo/Davinte_white_new.png";
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "next/router";
import {
  isChildrenPageActive,
  isParentPageActive,
} from "../../utils/daynamicNavigation";
import { useEffect, useState } from "react";
import { useSession, signIn, signOut } from "next-auth/react";
import { useSelector, useDispatch } from "react-redux";
import { SubscriptionStatus } from "../../utils/enums_backend";
import { getDaysBetweenDates } from "../../utils/common";
import { plansModalShow } from "../../redux/counterSlice";
import  QuickViewPanel from "../subscription/quickViewPanel";
import Favicon from './favIcons'


export default function Header01() {
  const [toggle, setToggle] = useState(false);
  const [isCollapse, setCollapse] = useState(null);
  const { data: session, status } = useSession();
  const dispatch = useDispatch();

  const { subStatus } = useSelector((state) => state.counter);
  // console.log("subStatus Header", subStatus);

  // window resize
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth >= 1024) {
        setToggle(false);
      }
    });
  });

  const route = useRouter();
  /* -------------------------------------------------------------------------- */
  /*                            daynamic navigations                            */
  /* -------------------------------------------------------------------------- */
  const home = {
    id: 1,
    name: "Home",
    path: "/",
  };

  const page = {
    id: 2,
    name: "Resources",
    pages: [
      {
        id: uuidv4(),
        name: "Blogs",
        condition: false,
        path: "/blogs",
      },{
        id: uuidv4(),
        name: "About Us",
        condition: false,
        path: "/about",
      },
      {
        id: uuidv4(),
        name: "Terms of Service",
        condition: false,
        path: "/terms",
      },
      {
        id: uuidv4(),
        name: "Privacy and Policy",
        condition: false,
        path: "/privacy",
      },
      {
        id: uuidv4(),
        name: "Careers",
        condition: false,
        path: "/careers",
      },
    ],
  };

  const mobileCollapse = (id) => {
    if (isCollapse === id) {
      return setCollapse(null);
    }
    setCollapse(id);
  };

  return (
    <>
      {/* main desktop menu sart*/}
      <Favicon />
      <header className="js-page-header fixed top-0 z-20 w-full backdrop-blur transition-colors bg-gray-50 bg-opacity-30 dark:bg-gray-700 dark:bg-opacity-20">
        <div className="flex items-center px-4 md:py-4 py-2 xl:px-24 ">
          <Link className="shrink-0" href="/">
            <div className="dark:hidden">
              <img
                src={"/images/logo/Davinte_black_new.png"} style={{ maxHeight: '1.2rem' }}
                alt="Davinte | AI Platform for Architects and Interior Designers - AI POWERED Rendering ENGINE"
                className="h-auto"
              />
            </div>
            <div className="hidden dark:block">
              <img
                src={"/images/logo/Davinte_white_new.png"} style={{ maxHeight: '1.2rem' }}
                alt="Davinte | AI Platform for Architects and Interior Designers - AI POWERED Rendering ENGINE"
                className="h-auto"
              />
            </div>
          </Link>
          {/* End  logo */}
          {/* End Desktop search form */}

          <div className="js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-10 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:flex lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent">
            <nav className="navbar w-full">
              <ul className="flex flex-col lg:flex-row">
                <li className="group">
                  <Link href="/">
                    <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                      <span
                        className={
                          isChildrenPageActive(route.asPath, "/")
                            ? "text-accent dark:text-accent"
                            : ""
                        }>
                        Home
                      </span>
                    </button>
                  </Link>
                </li>
                <li className="group">
                  <Link href="/ideas">
                    <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                      <span
                        className={
                          isChildrenPageActive(route.asPath, "/ideas")
                            ? "text-accent dark:text-accent"
                            : ""
                        }>
                        My Designs
                      </span>
                    </button>
                  </Link>
                </li>
                <li className="group">
                  <Link href="/explore">
                    <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                      <span
                        className={
                          isChildrenPageActive(route.asPath, "/explore")
                            ? "text-accent dark:text-accent"
                            : ""
                        }>
                        Gallery
                      </span>
                    </button>
                  </Link>
                </li>
                <li className="js-nav-dropdown group relative">
                  <button className="dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full">
                    <span
                      className={
                        isParentPageActive(page.pages, route.asPath)
                          ? "text-accent dark:text-accent"
                          : ""
                      }>
                      Resources
                    </span>
                    <i className="lg:hidden">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width={24}
                        height={24}
                        className="h-4 w-4 dark:fill-white">
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                      </svg>
                    </i>
                  </button>
                  <ul className="dropdown-menu left-0 top-[85%] z-10 hidden grid-flow-row grid-cols-[repeat(2,_1fr)] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 dark:bg-jacarta-800 lg:invisible lg:absolute lg:!grid lg:translate-y-4 lg:py-8 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative">
                    {page?.pages?.map((page) => (
                      <li key={page.id}>
                        <Link
                          href={page.path}
                          className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-xl px-5 py-2 transition-colors justify-between">
                          <span
                            className={`font-display ${
                              isChildrenPageActive(page.path, route.asPath)
                                ? "!text-accent !dark:text-accent"
                                : "text-jacarta-700 dark:text-white"
                            } text-sm `}>
                            {page.name}
                          </span>
                          {page.condition ? (
                            <span className="rounded bg-green py-1 px-2 text-tiny font-bold uppercase leading-none text-white ml-4">
                              new
                            </span>
                          ) : undefined}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>
            {/* End menu for desktop */}
            <div className="ml-8 hidden items-center lg:flex xl:ml-12">
              {/* End metamask Wallet */}

              <div className="js-nav-dropdown group-dropdown relative">
                {session ? (
                  <>
                    <button className="dropdown-toggle border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]">
                      <img
                        src={session.user.image}
                        alt=""
                        style={{
                          borderRadius: "50px",
                          width: "24px",
                          height: "24px",
                        }}
                        referrerPolicy="no-referrer"
                      />
                    </button>
                  </>
                ) : (
                  <button
                    onClick={() => signIn()}
                    className="dropdown-toggle border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={24}
                      height={24}
                      className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white">
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
                    </svg>
                  </button>
                )}
                {session && (
                  <div className="dropdown-menu dark:bg-jacarta-800 group-dropdown-hover:opacity-100 group-dropdown-hover:visible !-right-4 !top-[85%] !left-auto z-10 min-w-[14rem] whitespace-nowrap rounded-xl bg-white transition-all will-change-transform before:absolute before:-top-3 before:h-3 before:w-full lg:absolute lg:grid lg:!translate-y-4 lg:py-4 lg:px-2 lg:shadow-2xl hidden lg:invisible lg:opacity-0">
                    <QuickViewPanel setToggle={setToggle} />
                    <Link
                      href="/profile"
                      className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width={24}
                        height={24}
                        className="fill-jacarta-700 h-4 w-4 transition-colors dark:fill-white">
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
                      </svg>
                      <span className="font-display text-jacarta-700 mt-1 text-sm dark:text-white">
                        My Profile
                      </span>
                    </Link>
                    <button onClick={() => signOut()}>
                      <a className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width={24}
                          height={24}
                          className="fill-jacarta-700 h-4 w-4 transition-colors dark:fill-white">
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM7 11V8l-5 4 5 4v-3h8v-2H7z" />
                        </svg>
                        <span className="font-display text-jacarta-700 mt-1 text-sm dark:text-white">
                          Sign out
                        </span>
                      </a>
                    </button>
                  </div>
                )}
              </div>
              <DarkMode />
            </div>
            {/* End header right content (metamask and other) for desktop */}
          </div>
          {/* header menu conent end for desktop */}

          <div className="ml-auto flex lg:hidden">
            {session ? (
              <>
                <Link
                  href="/profile"
                  className="dropdown-toggle border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]">
                  <img
                    src={session.user.image}
                    alt=""
                    style={{
                      borderRadius: "50px",
                      width: "29px",
                      height: "29px",
                    }}
                    referrerPolicy="no-referrer"
                  />
                </Link>
              </>
            ) : (
              <button
                onClick={() => signIn()}
                className="dropdown-toggle border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width={24}
                  height={24}
                  className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white">
                  <path fill="none" d="M0 0h24v24H0z" />
                  <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
                </svg>
              </button>
            )}
            <DarkMode />
            <button
              className="js-mobile-toggle border-jacarta-100 hover:bg-accent dark:hover:bg-accent focus:bg-accent group ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
              aria-label="open mobile menu"
              onClick={() => setToggle(true)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                width={24}
                height={24}
                className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white">
                <path fill="none" d="M0 0h24v24H0z" />
                <path d="M18 18v2H6v-2h12zm3-7v2H3v-2h18zm-3-7v2H6V4h12z" />
              </svg>
            </button>
          </div>
          {/* End header right content  for mobile */}
        </div>
        {/* End flex item */}
      </header>
      {/* main desktop menu end */}

      {/* start mobile menu and it's other materials  */}
      <div
        className={`lg:hidden js-mobile-menu dark:bg-jacarta-800 invisible fixed inset-0 z-20 ml-auto items-center bg-white opacity-0 lg:visible lg:relative lg:inset-auto lg:bg-transparent lg:opacity-100 dark:lg:bg-transparent ${
          toggle ? "nav-menu--is-open" : "hidden"
        }`}>
        <div className="t-0 dark:bg-jacarta-800 fixed left-0 z-10 flex w-full items-center justify-between bg-white p-6 lg:hidden">
          <div className="dark:hidden">
            <img
              src={"/images/logo/Davinte_black_new.png"} style={{ maxHeight: '1.2rem' }}
              alt="Davinte | AI Platform for Architects and Interior Designers - AI POWERED Rendering ENGINE"
              className="h-auto"
            />
          </div>

          <div className="hidden dark:block">
            <img
              src={"/images/logo/Davinte_white_new.png"} style={{ maxHeight: '1.2rem' }}
              alt="Davinte | AI Platform for Architects and Interior Designers - AI POWERED Rendering ENGINE"
              className="h-auto"
            />
          </div>

          <button
            className="js-mobile-close border-jacarta-100 hover:bg-accent focus:bg-accent group dark:hover:bg-accent ml-2 flex h-10 w-10 items-center justify-center rounded-full border bg-white transition-colors hover:border-transparent focus:border-transparent dark:border-transparent dark:bg-white/[.15]"
            onClick={() => setToggle(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width={24}
              height={24}
              className="fill-jacarta-700 h-4 w-4 transition-colors group-hover:fill-white group-focus:fill-white dark:fill-white">
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
            </svg>
          </button>
        </div>

        <nav className="navbar w-full mt-20">
          <ul className="flex flex-col lg:flex-row">
            <li className="group" onClick={() => setToggle(false)}>
              <Link href="/">
                <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                  <span
                    className={
                      isChildrenPageActive("/", route.asPath)
                        ? "text-accent dark:text-accent"
                        : ""
                    }>
                    Home
                  </span>
                </button>
              </Link>
            </li>
            <li className="group" onClick={() => setToggle(false)}>
              <Link href="/ideas">
                <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                  <span
                    className={
                      isChildrenPageActive("/ideas", route.asPath)
                        ? "text-accent dark:text-accent"
                        : ""
                    }>
                    My Designs
                  </span>
                </button>
              </Link>
            </li>
            <li className="group" onClick={() => setToggle(false)}>
              <Link href="/explore">
                <button className="text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5">
                  <span
                    className={
                      isChildrenPageActive("/explore", route.asPath)
                        ? "text-accent dark:text-accent"
                        : ""
                    }>
                    Gallery
                  </span>
                </button>
              </Link>
            </li>
            <li className="js-nav-dropdown group relative">
              <button
                onClick={() => mobileCollapse(page.id)}
                className="dropdown-toggle text-jacarta-700 font-display hover:text-accent focus:text-accent dark:hover:text-accent dark:focus:text-accent flex items-center justify-between py-3.5 text-base dark:text-white lg:px-5 w-full">
                <span
                  className={
                    isParentPageActive(page.pages, route.asPath)
                      ? "text-accent dark:text-accent"
                      : ""
                  }>
                  Resources
                </span>
                <i className="lg:hidden">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    width={24}
                    height={24}
                    className="h-4 w-4 dark:fill-white">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                  </svg>
                </i>
              </button>
              <ul
                className={`dropdown-menu left-0 top-[85%] z-10 grid-flow-row grid-cols-[repeat(2,_1fr)] gap-x-4 whitespace-nowrap rounded-xl bg-white transition-all will-change-transform group-hover:visible group-hover:opacity-100 dark:bg-jacarta-800 lg:invisible lg:absolute lg:!grid lg:translate-y-4 lg:py-8 lg:px-2 lg:opacity-0 lg:shadow-2xl lg:group-hover:translate-y-2 relative ${
                  isCollapse === page.id ? "block" : "hidden"
                }`}
                aria-labelledby="navDropdown-4">
                {page?.pages?.map((page) => (
                  <li key={page.id} onClick={() => setToggle(false)}>
                    <Link
                      href={page.path}
                      className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center rounded-xl px-5 py-2 transition-colors">
                      <span
                        className={`font-display text-jacarta-700 text-sm dark:text-white ${
                          isChildrenPageActive(page.path, route.asPath)
                            ? "text-accent dark:text-accent"
                            : ""
                        }`}>
                        {page.name}
                      </span>
                    </Link>
                  </li>
                ))}
              </ul>
            </li>
          </ul>
        </nav>
        {/* End navbar mobile menu  */}

        <div className="mt-10 w-full lg:hidden">
          <hr className="dark:bg-jacarta-600 bg-jacarta-100 my-5 h-px border-0" />
          <div className="flex items-center justify-center">
            {session && (
              <div className="flex flex-col w-full">
                <div className="w-full flex flex-col justify-center items-stretch">
                  <QuickViewPanel setToggle={setToggle} />
                </div>
                <div className="w-full flex flex-col">
                  <Link
                    href="/profile"
                    className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={24}
                      height={24}
                      className="fill-jacarta-700 h-4 w-4 transition-colors dark:fill-white">
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M11 14.062V20h2v-5.938c3.946.492 7 3.858 7 7.938H4a8.001 8.001 0 0 1 7-7.938zM12 13c-3.315 0-6-2.685-6-6s2.685-6 6-6 6 2.685 6 6-2.685 6-6 6z" />
                    </svg>
                    <span className="font-display text-jacarta-700 mt-1 text-sm dark:text-white">
                      My Profile
                    </span>
                  </Link>
                  <button onClick={() => signOut()}>
                    <a className="dark:hover:bg-jacarta-600 hover:text-accent focus:text-accent hover:bg-jacarta-50 flex items-center space-x-2 rounded-xl px-5 py-2 transition-colors">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        width={24}
                        height={24}
                        className="fill-jacarta-700 h-4 w-4 transition-colors dark:fill-white">
                        <path fill="none" d="M0 0h24v24H0z" />
                        <path d="M12 22C6.477 22 2 17.523 2 12S6.477 2 12 2s10 4.477 10 10-4.477 10-10 10zM7 11V8l-5 4 5 4v-3h8v-2H7z" />
                      </svg>
                      <span className="font-display text-jacarta-700 mt-1 text-sm dark:text-white">
                        Sign out
                      </span>
                    </a>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        {/* mt-10 w-full lg:hidden */}
      </div>
      {/* End mobile menu and it's other materials */}
    </>
  );
}
