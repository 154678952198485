const footerMenuList = [
  {
    id: 1,
    title: "Major Links",
    diffClass: "md:col-start-9",
    list: [
      {
        id: 1,
        href: "/",
        text: "Home",
      },
      {
        id: 2,
        href: "/ideas",
        text: "My Designs",
      },
      {
        id: 3,
        href: "/explore",
        text: "Gallery",
      }
    ],
  },
  {
    id: 3,
    title: "Resources",
    diffClass: "",
    list: [
      {
        id: 0,
        href: "/about",
        text: "About Us",
      },
      {
        id: 1,
        href: "/blogs",
        text: "Blogs",
      },
      {
        id: 2,
        href: "/terms",
        text: "Terms of Service",
      },
      {
        id: 3,
        href: "/privacy",
        text: "Privacy and Policy",
      },
      {
        id: 4,
        href: "/careers",
        text: "Careers",
      },
    ],
  },
];

const socialIcons = [
  {
    id: 1,
    href: "https://www.facebook.com/davinteeai",
    text: "facebook",
  },
  {
    id: 2,
    href: "https://twitter.com/davinte_ai",
    text: "twitter",
  },
  {
    id: 3,
    href: "https://www.discord.com",
    text: "discord",
  },
  {
    id: 4,
    href: "https://www.instagram.com/davinte_ai",
    text: "instagram",
  },
  // {
  //   id: 5,
  //   href: "https://www.tiktok.com",
  //   text: "tiktok",
  // },
];

export { footerMenuList, socialIcons };
