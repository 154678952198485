import { useEffect, useState } from "react";
import Link from "next/link";
import { useSelector, useDispatch } from "react-redux";
import { SubscriptionStatus } from "../../utils/enums_backend";
import { useSession, signIn, signOut } from "next-auth/react";
import { getDaysBetweenDates } from "../../utils/common";
import { plansModalShow } from "../../redux/counterSlice";

export default function QuickViewPanel(props) {
    const dispatch = useDispatch();
    const { subStatus } = useSelector((state) => state.counter);
    const { setToggle } = props;
    return (
        <>
            {subStatus ? (
                <>
                    <div>
                        <span className="js-copy-clipboard text-jacarta-700 mt-2 flex select-none items-center whitespace-nowrap px-5 leading-none dark:text-white">
                            Your plan:
                        </span>
                        <span className="font-display js-copy-clipboard text-jacarta-700 mt-2 mb-4 flex select-none items-center whitespace-nowrap px-5 leading-none dark:text-white">
                            {subStatus.plan && subStatus.plan.name.replace('&', ' ')}
                        </span>
                        {
                            subStatus.amountPaid == 0 && (
                                <span
                                    onClick={() => {
                                        setToggle(false);
                                        dispatch(
                                            plansModalShow({
                                                mustSubscribe: false,
                                                noTrail: true,
                                            }))
                                    }}
                                    className="px-5  text-accent font-display font-semibold text-xs cursor-pointer">
                                    Subscribe to a Pro Plan
                                </span>
                            )
                        }
                        <span className="js-copy-clipboard text-sm text-jacarta-700 mt-2 mb-4 flex select-none items-center whitespace-nowrap px-5 leading-none dark:text-white">
                            Status:{" "}
                            <span className={
                                (subStatus.status == SubscriptionStatus.ACTIVE
                                    ? "text-green"
                                    : "text-red") + " ml-2"
                            }>
                                {subStatus.status.replace("_", " ")}
                            </span>
                        </span>
                    </div>
                    <div className="dark:border-jacarta-600 border-jacarta-100 mx-5 mb-6 rounded-lg border p-4">
                        <span className="dark:text-jacarta-200 text-sm font-medium tracking-tight">
                            Balance
                        </span>
                        <div className="flex items-center my-3">
                            <svg className="icon icon-ETH -ml-1 mr-1 h-[1.125rem] w-[1.125rem]">
                                <use xlinkHref="/icons.svg#icon-ETH" />
                            </svg>
                            <span className={(subStatus.status == SubscriptionStatus.ACTIVE
                                ? "text-green"
                                : "text-red") + "text-lg font-bold"}>
                                {subStatus.budgetRemaining}
                            </span>
                            <span className="dark:text-jacarta-200 text-base font-bold ml-2">
                                Designs
                            </span>
                        </div>
                        {subStatus.amountPaid == 0 && // or the auto renew is turned off
                            (
                                <>
                                    <div className="flex items-center">
                                        <span className="text-sm font-bold">
                                            {subStatus.status == SubscriptionStatus.ACTIVE
                                                && "Ends in " + getDaysBetweenDates(
                                                    new Date(),
                                                    new Date(subStatus.endDate)
                                                ) + " Days"}
                                            {subStatus.status == SubscriptionStatus.EXPIRED &&
                                                "Ended " + Math.abs(
                                                    getDaysBetweenDates(
                                                        new Date(),
                                                        new Date(subStatus.endDate)
                                                    )
                                                ) + " days ago"}
                                        </span>
                                    </div>
                                </>

                            )}
                        <div className="flex items-center" onClick={() => setToggle(false)}>
                            <Link href="/profile" className="text-sm mt-3 text-accent font-display font-semibold">
                                Click for details
                            </Link>
                        </div>
                    </div>
                    {
                        subStatus.status != SubscriptionStatus.ACTIVE && (
                            <div>
                                <span className="js-copy-clipboard font-display text-jacarta-700 my-4 flex select-none items-center whitespace-nowrap px-5 leading-none dark:text-white">
                                    No plan active
                                </span>
                                <span className="js-copy-clipboard text-sm text-jacarta-700 my-4 flex select-none items-center whitespace-nowrap px-5 leading-none dark:text-white">
                                    <div className="w-full pb-5 mb-5 border-b-2 border-accent-lighter">
                                        <span
                                            onClick={() => {
                                                setToggle(false);
                                                dispatch(plansModalShow({
                                                    mustSubscribe: false,
                                                    noTrail: (subStatus) ? true : false
                                                }))
                                            }}
                                            className="cursor-pointer dark:border-jacarta-600 border-white dark:bg-accent bg-accent text-white flex w-full items-center justify-center border-2 px-8 text-center font-semibold transition-all hover:border-transparent hover:dark:border-transparent  dark:text-white rounded-md py-2">
                                            {subStatus.amountPaid == 0 && "Subscribe to a Pro Plan"}
                                            {(subStatus.amountPaid != 0 && subStatus.status == SubscriptionStatus.EXHAUSTED) && "Add a top-up"}
                                            {(subStatus.amountPaid != 0 && subStatus.status == SubscriptionStatus.EXPIRED) && "Renew your plan"}
                                            {(subStatus.amountPaid != 0 && (subStatus.status != SubscriptionStatus.EXPIRED || subStatus.status != SubscriptionStatus.EXHAUSTED)) && "Renew your plan"}
                                        </span>
                                    </div>
                                </span>
                            </div>
                        )
                    }
                </>
            ) : (
                <>
                    <div>
                        <span className="js-copy-clipboard font-display text-jacarta-700 my-4 flex select-none items-center whitespace-nowrap px-5 leading-none dark:text-white">
                            {`You dont have a plan yet`}
                        </span>
                        <span className="js-copy-clipboard text-sm text-jacarta-700 my-4 flex select-none items-center whitespace-nowrap px-5 leading-none dark:text-white">
                            <div className="w-full pb-5 mb-5 border-b-2 border-accent-lighter">
                                <span
                                    onClick={() => {
                                        setToggle(false);
                                        dispatch(plansModalShow())
                                    }}
                                    className="button-main">
                                    Subscribe to a Plan
                                </span>
                            </div>
                        </span>
                    </div>
                </>
            )}
        </>
    )
}
