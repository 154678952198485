import React, { useEffect, useState } from "react";
import Link from "next/link";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Meta from "../../components/Meta";
import { signIn, getProviders  } from "next-auth/react";

export default function LoginBody() {
    const [providers, setProviders] = useState(null);
    useEffect(() => {
        // React advises to declare the async function directly inside useEffect
        async function getProvidersAsync() {
            const providers = await getProviders()
          setProviders(providers);
        };
    
        // You need to restrict it at some point
        // This is just dummy code and should be replaced by actual
        if (!providers) {
            getProvidersAsync();
        }
      }, []);

    return (
        <div
            className="tab-pane fade"
            id="torus"
            aria-labelledby="torus-tab">
            {providers && Object.values(providers).map((provider) => {
                if (provider.name === "Email") {
                    return;
                }
                return (
                    // <button
                    //     key={provider.name}
                    //     onClick={() => signIn(provider.id)}
                    //     className="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 dark:hover:bg-accent hover:bg-accent text-jacarta-700 mb-4 flex w-full items-center justify-center border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent rounded-md py-2 my-3">
                    //     <span>Sign in/up with {provider.name}</span>
                    // </button>
                    
                    <button
                        key={provider.name}
                        onClick={() => signIn(provider.id)}
                        className="border-jacarta-100 text-jacarta-700 mb-4 flex w-full items-center justify-center border-2 bg-white py-4 px-8 text-center font-semibold transition-all hover:border-accent  rounded-md py-2 my-3">
                        <div className="w-3/10 min-w-30">
                        {
                        provider.name == 'Google' ? 
                            (<img src="/images/logo/g-logo.png" alt="Google Login" className="float-right" style={{height: '18px', width: '18px'}} />) :
                            (<img src="/images/logo/174857.png" alt="Google Login" className="float-right" style={{height: '18px', width: '18px'}} />)}
                        </div>
                        <span className="flex-1 text-left ml-5">Sign in/up with {provider.name}</span>
                    </button>
                    
                );
            })}
        </div>
    )
}
