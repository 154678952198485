import Link from "next/link";
import { SubscriptionStatus } from './enums_backend';
import { GlobalActionTypes, ComponentTypes } from './enums';
import { subscription } from './selectFields';
import { loginModalShow, plansModalShow, plansModalHide, setSubStatus, renewSubModalShow, loginModalHide } from '../redux/counterSlice';

export function isSubValid(sub) {
    if (sub.status == SubscriptionStatus.ACTIVE || sub.budgetRemaining > 0 || (new Date(sub.endDate)) >= (new Date())) {
        return true;
    }

    return false;
}

export function isSubActiveButInvalid(sub) {
    if ((sub.status == SubscriptionStatus.ACTIVE && sub.budgetRemaining <= 0)) {
        return {
            activeButInvalid: true,
            status: SubscriptionStatus.EXHAUSTED
        };
    }
    else if (sub.status == SubscriptionStatus.ACTIVE && (new Date(sub.endDate)) <= (new Date())) {
        return {
            activeButInvalid: true,
            status: SubscriptionStatus.EXPIRED
        }
    }

    return {
        activeButInvalid: false,
        status: SubscriptionStatus.ACTIVE
    }
}

export async function getFreePlan(prisma) {
    const trailPlan = await prisma.plan.findMany({
        where: {
            price: 0,
            active: true
        },
    });
    if (trailPlan && trailPlan.length > 0) {
        return trailPlan[0];
    }
    // console.log('trailPlan', trailPlan);
    return null
}

export async function getLatesSub(user, prisma) {
    const subs = await prisma.subscriptions.findMany({
        where: {
            userId: user.id,
        },
        select: subscription,
        orderBy: {
            createdAt: 'desc'
        },
        take: 1
    });
    if (!subs || subs.length == 0) {
        return null;
    }

    if (!subs) {
        return null;
    }

    return subs[0];
}


export async function accountCheck(user, prisma) {
    let subs = await prisma.subscriptions.findMany({
        where: {
            userId: user.id,
            status: SubscriptionStatus.ACTIVE,
        },
        select: subscription,
    });

    // console.log('subs', subs);

    if (!subs || subs.length == 0) {
        console.log('No active subs found');
        return await getLatesSub(user, prisma);
    }

    subs = await prisma.subscriptions.findMany({
        where: {
            userId: user.id,
        },
        select: subscription,
        orderBy: {
            createdAt: 'desc'
        },
    });

    let activeSubs = []
    for (const sub of subs) {
        // console.log('sub', sub);
        // if (sub.status == SubscriptionStatus.EXPIRED ||
        //     sub.status == SubscriptionStatus.IN_ACTIVE ||
        //     sub.status == SubscriptionStatus.SUSPENDED ||
        //     sub.status == SubscriptionStatus.EXHAUSTED ||
        //     sub.status == SubscriptionStatus.PENDING_PAYMENT) {
        //     // console.log('sub status is not active', sub.id);
        //     continue;
        // }

        if (sub.status != SubscriptionStatus.ACTIVE) {
            continue;
        }

        const planActive = isSubValid(sub);
        if (planActive) {
            activeSubs.push(sub)
        }
        else {
            const { activeButInvalid, status } = isSubActiveButInvalid(sub);
            console.log(`activeButInvalid 1 [${activeButInvalid}] - status [${status}]`);
            if (activeButInvalid) {
                const updateSub = await prisma.subscriptions.update({
                    where: {
                        id: sub.id
                    },
                    data: {
                        status: status,
                    }
                });
            }
            else {
                console.log('sub status is active but not valid', sub.id);
            }
        }
    }

    if (activeSubs.length == 0) {
        return await getLatesSub(user, prisma);
    }

    if (activeSubs.length > 1) {
        console.log('More than one active subscription');
        return activeSubs[0];
    }

    if (activeSubs.length == 1) {
        console.log('All good');
        return activeSubs[0];
    }

    console.log('A wired case');
    return null;
}


export async function getPlans(prisma) {
    return await prisma.plan.findMany({
        where: {
            active: true
        },
        orderBy: {
            price: 'asc'
        }
    });
}


export function globalActionWatchFactory(props) {
    const { action, session, subStatus, dispatch } = props;

    switch (action) {
        case GlobalActionTypes.LIKE_IDEA:
            if (!session) {
                dispatch(loginModalShow());
                return false;
            }

            return true;
            break;
        case GlobalActionTypes.EXPLORE_FAV_PAGE:
            if (!session) {
                dispatch(loginModalShow());
                return false;
            }

            return true;
            break;
        case GlobalActionTypes.EXPLORE_NEXT_PAGE:
            if (!session) {
                dispatch(loginModalShow());
                return false;
            }

            return true;
            break;
        case GlobalActionTypes.PARENT_CLICKED:
            if (!session) {
                dispatch(loginModalShow());
                return false;
            }
            else if (!subStatus) {
                dispatch(plansModalShow());
                return false;
            }
            else if (subStatus && subStatus.amountPaid == 0) {
                dispatch(plansModalShow({
                    mustSubscribe: false,
                    noTrail: true
                }))
                return false;
            }
            return true;
            break;
        case GlobalActionTypes.HOMEPAGE_CREATE_DESIGN:
            if (!session || !subStatus) {
                dispatch(loginModalShow());
                dispatch(plansModalHide());
                return false;
            }
            return true;
            break;
        case GlobalActionTypes.SHARE_DESIGN:
            if (!session || !subStatus) {
                dispatch(loginModalShow());
                return false;
            }
            return true;
        default:
        // code block
    }
    return false;
}

export function designFeatureAccessControl(session, subStatus, componentType) {
    const plan = subStatus? subStatus.plan : null;
    const metadata = (plan && plan.metadata) ? JSON.parse(plan.metadata) : null;
    switch (componentType) {
        case ComponentTypes.PUBLIC_VISIBILITY:
            return true;
            break;
        case ComponentTypes.PRIVATE_VISIBILITY:
            if (!session || !subStatus) {
                return false;
            }
            let { plan: visibPlan } = subStatus;
            if (visibPlan && visibPlan.isPrivateSupported) {
                return true;
            }
            else {
                return false;
            }
            return false;
            break;
        case ComponentTypes.MODEL_1:
        case ComponentTypes.MODEL_2:
        case ComponentTypes.MODEL_3:
        case ComponentTypes.MODEL_8:
        case ComponentTypes.MODEL_4:
        case ComponentTypes.MODEL_5:
            if (!session || !subStatus) {
                return false;
            }

            if (!plan) {
                return false;
            }

            const supportedModels = plan.supportedModels.find((model) => model.toUpperCase().includes(componentType));
            if (supportedModels && supportedModels.length > 0) {
                return true;
            }
            return false
            break;
        case ComponentTypes.UPSCALE:
            if (!plan) {
                return false;
            }
            
            // console.log('metadata', metadata);
            if (!metadata || !metadata.upscale) {                
                return false;
            }
            
            return true;
            break;
        case ComponentTypes.CUSTOM_COLORS:
            if (!plan) {
                return false;
            }

            // console.log('metadata', metadata);
            if (!metadata || !metadata.colorPalette || !metadata.colorPalette.customColors) {
                // console.log('customColors not supported');
                return false;
            }

            // console.log('customColors is support');
            
            return true;
            break;
        default:
        // code block
    }
    return false;
}

export function isPlanValid(subStatus, plan, isButton = false) {
    let planName = null;
    let trailAvailable = true;
    if (subStatus && subStatus.plan) {
        if (subStatus.status == SubscriptionStatus.ACTIVE) {
            planName = subStatus.plan.name;
        } else {
            trailAvailable = false;
        }
    }

    return (planName && ((planName == plan.name || plan.price == 0) || (planName != plan.name && plan.price < subStatus.plan.price)) ? (
        <span className="flex dark:border-jacarta-500 border-jacarta-200 bg-transparent dark:bg-transparent text-gray-800 dark:text-white w-full items-center justify-center border-1 px-8 text-center font-semibold transition-all rounded-md py-2">
            {
                planName == plan.name ? 'Your Current Plan' : 'Not Available'
            }
        </span>
    ) : (!trailAvailable && plan.price == 0) ? (
        <span className="flex dark:border-jacarta-500 border-jacarta-200 bg-transparent dark:bg-transparent text-gray-800 dark:text-white w-full items-center justify-center border-1 px-8 text-center font-semibold transition-all rounded-md py-2">
            Not Available
        </span>
    ) : isButton ? (
        <button className="button-main">
            {
                planName ? 'Upgrade' : 'Subscribe'
            }
        </button>
    ) : (
        <Link href={`/checkout/${plan.name}`} className="button-main">
            {
                planName ? 'Upgrade' : 'Subscribe'
            }
        </Link>
    ))
}

export async function logActivity(props, prisma) {
    if (!prisma) {
        console.log('Prisma client is not available')
        return false;
    }

    const { userId, designId, actionType, actionValue, actionStatus } = props;
    console.log(`Activity logged for [${actionType}] - [${actionValue}]`)
    
    const log = await prisma.logs.create({
        data: {
            userId: userId,
            designId: designId,
            actionType: actionType,
            actionValue: actionValue,
            actionStatus: actionStatus
        }
    })
}
