// Description: explain this page: what is it for, what is the purpose of this page
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    likedIdeas: [],
    unlikedIdeas: [],
    focusedDesign: null,
};

export const exploreSlice = createSlice({
    name: "explore",
    initialState,
reducers: {
        handleLikeIdea: (state, action) => {
            const payload = action.payload;
            if (payload.likedIdeaStatus) {
                // console.log('Adding the idea to the state.likedIdeas', payload.likedIdea);
                state.likedIdeas.push(payload.likedIdea);
                state.unlikedIdeas = state.unlikedIdeas.filter((idea) => idea.id !== payload.likedIdea.id);
            }
            else {
                // console.log('Removing the idea', payload.likedIdea);
                state.likedIdeas = state.likedIdeas.filter((idea) => idea.id !== payload.likedIdea.id);
                state.unlikedIdeas.push(payload.likedIdea);
            }
            // console.log('Payload', payload);
            // console.log('likedIdeas in reducer', state.likedIdeas);
        },
        setFocusedDesign: (state, payload) => {
            state.focusedDesign = payload.focusedDesign;
        }
    },
});

// Action creators are generated for each case reducer function
export const {
    handleLikeIdea,
    setFocusedDesign,
} = exploreSlice.actions;

export default exploreSlice.reducer;
