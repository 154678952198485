export const SubscriptionStatus = {
	ACTIVE: "ACTIVE",
	SUSPENDED: "SUSPENDED",
	EXPIRED: "EXPIRED",
	PENDING_PAYMENT: "PENDING_PAYMENT",
	EXHAUSTED: "EXHAUSTED",
	IN_ACTIVE: "IN_ACTIVE"
}

export const SubInterval = {
	MONTHLY: "Monthly",
	ANNUALLY: "Annually",
	DAILY: "Days",
}

export const ActivityLogs = {
	USER_LOGIN: "USER_LOGIN",
	USER_SIGNUP: "USER_SIGNUP",
	UPGRADE_CLICKED: "UPGRADE_CLICKED",
	PAYMENT_COMPLETED: "PAYMENT_COMPLETED",
	PLANS_VIEWED: "PLANS_VIEWED",
	SUB_INACTIVE_ALERT: "SUB_INACTIVE_ALERT",
	WEBHOOK_RECEIVED: "WEBHOOK_RECEIVED",
	UPGRADE_VIEWED: "UPGRADE_VIEWED",

}

export const ActivityStatus = {
	WEBHOOK_SIG_FAILED: "WEBHOOK_SIG_FAILED",
	WEBHOOK_UNCAPTURED: "WEBHOOK_UNCAPTURED",
	WEBHOOK_PAY_SUCCESS_NEW: "WEBHOOK_PAY_SUCCESS_NEW",
	WEBHOOK_PAY_SUCCESS_UPGRADE: "WEBHOOK_PAY_SUCCESS_UPGRADE",
	WEBHOOK_NO_PLAN_USER: "WEBHOOK_NO_PLAN_USER",
	UPGRADE_NOT_PROCESSED: "UPGRADE_NOT_PROCESSED",
	PAYMENT_LINK_GENERATION_FAILED: "PAYMENT_LINK_GENERATION_FAILED",
	PAYMENT_LINK_GENERATED: "PAYMENT_LINK_GENERATED",
}