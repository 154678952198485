export const IdeasStatus = {
	NOT_SUBMITTED: "NOT_SUBMITTED",
	SUBMITTED: "SUBMITTED",
	COMPLETED: "COMPLETED",
	FAILED: "FAILED"
}

export const CoreDesignPosition = {
	SIDE: 'SIDE',
	TOP: 'TOP',
	BOTTOM: 'BOTTOM',
	CENTERED: 'CENTERED'
}

export const SpaceType = {
	INTERIOR: 'INTERIOR',
	EXTERIOR: 'EXTERIOR',
}

export const DesignVisibility = {
	PUBLIC: 'Public',
	PRIVATE: 'Private'
}

export const URLs = {
	GET_ONE_DESIGN: '/api/designs/get_one',
	GET_ONE_DESIGN_EXPLORE: '/api/designs/get_one_explore',
	GET_ALL_PLANS: '/api/plans/get_all',
	ADD_DESIGN: '/api/designs/add',
	GET_DESIGN_STATUS: '/api/designs/get_status',
	GET_DESIGNS_HERO: '/api/designs/get_hero',
	ADD_SUB: '/api/subs/add',
	CREATE_CHECKOUT_SESSION: '/api/stripe-back/create-checkout-session',
	GET_INVOICES: '/api/stripe-back/invoices',
	GET_EXPLORE: '/api/designs/get_explore',
	ADD_LIKE: '/api/quickActions/likes',
	SHARE_DESIGN: '/api/designs/share',
	ADD_SHARE_CHANNEL: '/api/designs/shareChannelUpdate',
	GET_TERMS_STATUS: '/api/subs/getTermsStatus',
	ACCEPT_TERMS: '/api/subs/acceptTerms',
	UPDATE_POST: '/api/posts/updatePost',
}

export const CoreDesignUseCase = {
	UPLOAD_NEEDED: 'UPLOAD_NEEDED',
	EXISTING_IMAGE: 'EXISTING_IMAGE',
}

export const IdeasActions = {
	EDIT: 'EDIT',
	UPSCALE: 'UPSCALE',
	VARIATION: 'VARIATION',
	RE_DESIGN: 'RE_DESIGN',
	NEW_DESIGN: 'NEW_DESIGN',
	REFINE: "REFINE"
}

export const ErrorCodes = {
	NO_SUBSCRIPTION: 406,
	PAYLOAD_ERROR: 402,
	NOT_LOGGED_IN: 401,
	SERVER_SIDE_ERROR: 500,
	SUCCESS_RETURN: 200,
	SUCCESS_ADD: 201,
	TRAIL_USED: 403,
}

export const InvoiceStatus = {
	NEW_SUBCRIPTION: 'NEW_SUBCRIPTION',
	RENEW_SUBCRIPTION: 'RENEW_SUBCRIPTION',
	UPGRADE_SUBCRIPTION: 'UPGRADE_SUBCRIPTION',
}

export const PaymentStatus = {
	PAID: 'PAID',
	FAILED: 'FAILED',
	CANCELLED: 'CANCELLED',
}

export const PlansListType = {
	SWIPER: 'SWIPER',
	GRID: 'GRID'
}

export const GlobalActionTypes = {
	LIKE_IDEA: 'LIKE_IDEA',
	EXPLORE_COMMUNITY: 'EXPLORE_COMMUNITY',
	EXPLORE_NEXT_PAGE: 'EXPLORE_NEXT_PAGE',
	EXPLORE_FAV_PAGE: 'EXPLORE_FAV_PAGE',
	EXPLORE_TOP_DESIGNS: 'EXPLORE_TOP_DESIGNS',
	GENERATE_IDEAS: 'GENERATE_IDEAS',
	HOMEPAGE_CREATE_DESIGN: 'HOMEPAGE_CREATE_DESIGN',
	PARENT_CLICKED: 'PARENT_CLICKED',
	SHARE_DESIGN: 'SHARE_DESIGN',
}

export const ComponentTypes = {
	PRIVATE_VISIBILITY: 'PRIVATE_VISIBILITY',
	PUBLIC_VISIBILITY: 'PUBLIC_VISIBILITY',
	CUSTOM_COLORS: 'CUSTOM_COLORS',
	UPSCALE: 'UPSCALE',
	MODEL_1: 'MODEL_1',
	MODEL_2: 'MODEL_2',
	MODEL_3: 'MODEL_3',
	MODEL_4: 'MODEL_4',
	MODEL_5: 'MODEL_5',
	MODEL_6: 'MODEL_6',
	MODEL_7: 'MODEL_7',
	MODEL_8: 'MODEL_8',
}

export const ShareContentTypes = {
	DESIGN: 'DESIGN',
	IDEA: 'IDEA',
}

export const ShareChannelTypes = {
	FACEBOOK: 'FACEBOOK',
	TWITTER: 'TWITTER',
	LINKEDIN: 'LINKEDIN',
	PINTEREST: 'PINTEREST',
	EMAIL: 'EMAIL',
	LINKEDIN: 'LINKEDIN',
	WHATSAPP: 'WHATSAPP',
	REDDIT: 'REDDIT',
	TELEGRAM: 'TELEGRAM',
}

export const CommonConfigs = {
	MAX_IDEAS_PAGESIZE: 10
}

export const DesignMethod = {
	IMG_TO_IMG: 'IMG_TO_IMG',
	IMG_TO_IMG_SDXL: 'IMG_TO_IMG_SDXL',
	CNET_CANNY: 'CNET_CANNY',
	CNET_MLSD: 'CNET_MLSD',
	CNET_HED: 'CNET_HED',
	IN_PAINTING: 'IN_PAINTING',
	CNET_CANNY_IMG: 'CNET_CANNY_IMG',
	CNET_MLSD_DEPTH: 'CNET_MLSD_DEPTH',
	CNET_HED_IMG: 'CNET_HED_IMG',
	UPSCALE: 'UPSCALE',
	CNET_SDXL_CANNY: "CNET_SDXL_CANNY"
}

export const IdeaType = {
	IDEA: 'IDEA',
	CANVAS: 'CANVAS',
	MIDDLE: 'MIDDLE',
	FINAL: 'FINAL'
}


export const ConditionTypes = {
	WEATHER: "WEATHER",
	TIME_OF_DAY: "TIME_OF_DAY",
	CLIMATE: "CLIMATE",
	LANDSCAPE: "LANDSCAPE",
	SEASON: "SEASON",
}
