import Link from "next/link";
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { plansModalHide } from "../../redux/counterSlice";
import { MdKeyboardArrowRight, MdKeyboardArrowLeft } from "react-icons/md";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar } from "swiper";
import { SubscriptionStatus } from "../../utils/enums_backend";
import { isPlanValid } from "../../utils/managedSub";
import "swiper/css";
import "swiper/css/navigation";


const freeUserMetaData = {
    'resolution': 650,
    'upscale': null,
    'watermark': true,
    'colorPalette': {
        'customColors': false
    }
}

const publicUserMetaData = {
   'resolution': 900,
   'upscale': {
       'x': 2
   },
   'watermark': false,
   'colorPalette': {
       'customColors': true
   }
}

const privateUserMetaData = {
    'resolution': 900,
    'upscale': {
        'x': 4
    },
    'watermark': false,
    'colorPalette': {
        'customColors': true
    }
}

export default function PlanList(props) {
    const { plans, noTrail, subStatus, planPage } = useSelector((state) => state.counter);
    const dispatch = useDispatch();

    const { displayType } = props;

    const plansDescription = [
        ["Free tier - No credit card is required", "Active for 7 days", "Up top 50 designs", "Access to the Color Palette", "Access to two of the most powerful models", "Max resolution 650x650", "Ability to share your designs", "No commercial use"
        ],
        ["Billed monthly", "Generate up to 1000 designs per month", "You're designs are publicly accessible", "Access to the Color Palette", "Custom Colors for the Color Palette", "Access to all the model types (AI Apprentice and AI Navigator, and AI Saga)", "Upto 2X Upscale", "Ability to save and share designs", "Commercial use", "Cancel at any time"
        ],
        ["Generate up to 2200 designs per month", "1 Seat", "Your designs are kept private*", "Access to all model variations", "Upto 4X Upscale", "Billed monthly", "Commercial use", "Cancel at any time", "Custom Colors for the Color Palette"
        ],
        ["Billed annually", "All the great features of Solo Designer", "Generate a total of 27000 designs per year", "Enjoy two months free compared to the monthly plan", "Up to 3 users, simultaneously", "Priority support", "Early access to new features and updates", "Cancel at any time"
            // "Export designs in high-resolution images",
            // "Integration with popular interior design software",
            // "Additional customization options for furniture and decor",
            // "Personalized design recommendations based on user preferences",
        ]
    ]

    const settings = {
        className: "slider variable-width",
        dots: true,
        infinite: true,
        centerMode: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        variableWidth: true
    };

    function showPageIndex(subStatus, plan) {
        let planName = null;
        let trailAvailable = true;
        if (subStatus && subStatus.plan) {
            if (subStatus.status == SubscriptionStatus.ACTIVE) {
                planName = subStatus.plan.name;
            } else {
                trailAvailable = false;
            }
        }

        if (planName && (planName != plan.name) && (plan.price > subStatus.plan.price)) {
            return true
        } else {
            return false
        }
    }

    const renderPlans = plans && plans.map((plan, index) => {
        let disableTrail = false;
        if (subStatus) {
            disableTrail = true;
        }
        const jsonStatementDescriptor = plan.statementDescriptor.split('", "')

        return (
            <div key={index} className="dark:bg-jacarta-700  relative border-jacarta-100 block border dark:border-jacarta-900 bg-white p-[1.1875rem] transition-shadow hover:shadow-lg overflow-hidden">
                {plan.isPopular && <div className="ribbon2 right-rib bg-accent-light text-white py-1">Most Popular</div>}
                <div className="flex flex-col justify-center items-center py-5 px-2 dark:text-white">
                    <div className="text-base font-bold text-center h-10">{plan.name.split('&').map((item, index) => {
                        return (
                            <span key={index}>
                                {item}<br />
                            </span>
                        )
                    })}</div>
                    <div className="w-full h-[120px] object-cover flex flex-col justify-center items-center my-3">
                        <span className="text-2xl mb-3">
                            {plan.currency} ${plan.price}
                        </span>
                        <span className="text-lg">
                            {(plan.price == 0) ? `Free for ${plan.intervalCount} Days` : `${plan.interval}`}
                        </span>
                        <span className="text-sm text-jacarta-800 dark:text-jacarta-200 my-2 text-center">
                            Up to {plan.budget} Designs<br />
                        </span>
                    </div>
                    <div className="w-full pb-10 mb-10 border-b-2 border-accent-lighter flex justify-center" onClick={() => dispatch(plansModalHide())}>
                        <Link href={`/checkout/${plan.name}`} className="button-main">
                            Subscribe
                        </Link>
                    </div>
                    <div className="w-full flex flex-col justify-center">
                        <ul className="list-disc max-w-md space-y-1 list-inside">
                            {
                                jsonStatementDescriptor.map((desc, index) => {
                                    return (
                                        <li key={index} className="text-sm text-gray-500 flex py-1">
                                            <svg className="w-4 h-5 mr-1.5 text-accent-lighter dark:text-accent flex-shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
                                            {desc.replaceAll('"', '')}
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </div>
                </div>
            </div>
        )
    });

    const [indexId, setIndexId] = useState(0);
    const swiperRef = useRef(null);

    useEffect(() => {
        for (var i = 0; i < plans.length; i++) {
            const isShow = showPageIndex(subStatus, plans[i]);
            // console.log('isShow', isShow, 'index', i);
            if (isShow) {
                // console.log('setting', i);
                setIndexId(i);
                break;
            }
        }
    }, []);

    useEffect(() => {
        if (swiperRef.current && swiperRef.current.swiper) {
            const swiper = swiperRef.current.swiper;
            swiper.slideTo(indexId);
          }
    }, [swiperRef.current]);

    return (
        <>
            <Swiper
                modules={[Navigation, Pagination, Scrollbar]}
                spaceBetween={30}
                slidesPerView="auto"
                loop={false}
                simulateTouch={true}
                ref={swiperRef}
                breakpoints={{
                    400: {
                        slidesPerView: 1
                    },
                    600: {
                        slidesPerView: 2
                    },
                    900: {
                        slidesPerView: 3,
                    },
                }}
                navigation={{
                    nextEl: ".bids-swiper-button-next",
                    prevEl: ".bids-swiper-button-prev",
                }}
                className="w-full h-full px-5 rounded-lg overflow-hidden"
            >
                {
                    plans && plans.map((plan, index) => {
                        const jsonStatementDescriptor = plan.statementDescriptor.split('", "')
                        return (
                            <SwiperSlide key={index} className={`w-full h-full flex-col justify-end`}>
                                <div className={`dark:bg-jacarta-700 relative border-jacarta-200 dark:border-jacarta-600 rounded-md block border dark:bg-transparent bg-transparent p-[1.1875rem] transition-shadow hover:shadow-lg overflow-hidden`}>
                                    {plan.isPopular && <div className="ribbon2 right-rib bg-accent-light text-white py-1">Most Popular</div>}
                                    <div className="flex flex-col justify-start items-center py-5 px-2 dark:text-white" style={{ minHeight: '700px' }}>
                                        <div className="text-base font-bold text-center h-10">{plan.name.split('&').map((item, index) => {
                                            return (
                                                <span key={index}>
                                                    {item}<br />
                                                </span>
                                            )
                                        })}</div>
                                        <div className="w-full h-[120px] object-cover flex flex-col justify-center items-center my-3">
                                            <span className="text-2xl mb-3">
                                                {plan.currency} ${plan.price}
                                            </span>
                                            <span className="text-lg">
                                                {(plan.price == 0) ? `Free for ${plan.intervalCount} Days` : `${plan.interval}`}
                                            </span>
                                            <span className="text-sm text-jacarta-800 dark:text-jacarta-200 my-2 text-center">
                                                Up to {plan.budget} Designs<br />
                                            </span>
                                        </div>
                                        <div className="w-full pb-10 mb-10 border-b-2 flex justify-center border-accent-lighter">
                                        {/* onClick={() => dispatch(plansModalHide())} */}
                                            {
                                                isPlanValid(subStatus, plan)
                                            }
                                        </div>
                                        <div className="w-full flex flex-col justify-center">
                                            <ul className="list-disc max-w-md space-y-1 list-inside">
                                                {
                                                    jsonStatementDescriptor.map((desc, index) => {
                                                        return (
                                                            <li key={index} className="text-sm dark:text-gray-400 text-gray-600 flex py-1">
                                                                <svg className="w-4 h-5 mr-1.5 text-accent-lighter dark:text-accent flex-shrink-0" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd"></path></svg>
                                                                {desc.replaceAll('"', '')}
                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                        )
                    })
                }
            </Swiper>
            <div className="group bids-swiper-button-prev swiper-button-prev shadow-white-volume absolute !top-1/2 left-2 z-10 -mt-6 flex !h-12 !w-8 cursor-pointer items-center justify-center rounded-lg bg-white py-3 px-1 text-jacarta-700 text-xl md:!left-2 after:hidden">
                <MdKeyboardArrowLeft />
            </div>
            <div className="group bids-swiper-button-next swiper-button-next shadow-white-volume absolute !top-1/2 right-2 z-10 -mt-6 flex !h-12 !w-8 cursor-pointer items-center justify-center rounded-lg bg-white py-3 px-1 text-jacarta-700 text-xl md:!right-3 after:hidden">
                <MdKeyboardArrowRight />
            </div>
        </>
    )
}
