import Head from "next/head";
import { NextSeo, CollectionPageJsonLd, SocialProfileJsonLd } from "next-seo";

const Meta = ({ title, keyword, desc, images, subTitle, datePublised }) => {
  if (!desc) {
    desc =
      "Discover Davinte.AI, the cutting-edge AI Rendering Engine for professionals. Create stunning renderings of your space in seconds with our generative AI technology. Experience the future of AI rendering today!";
  }

  const titleRefined = title;
  let finalImages = images;
  if (!images) {
    finalImages = [
      {
        url: "https://davinte.nyc3.cdn.digitaloceanspaces.com/assets/hero/hero-davinte-ai-desktop.jpg",
        alt: "Discover Davinte.AI, the cutting-edge AI Rendering Engine for professionals. Create stunning renderings of your space in seconds with our generative AI technology. Experience the future of AI rendering today!",
        type: "image/jpeg",
        width: 770,
        height: 600,
      },
    ];
  }

  const parts = finalImages[0]["url"].split("/");
  const imageName = parts[parts.length - 1];

  return (
    <>
      <NextSeo
        title={titleRefined}
        description={desc}
        titleTemplate="Davinte.AI | %s"
        canonical={process.env.NEXT_PUBLIC_ROOT_PATH}
        openGraph={{
          url: process.env.NEXT_PUBLIC_ROOT_PATH,
          title: `Davinte.AI | ${titleRefined}`,
          description: desc,
          images: finalImages,
          siteName: "Davinte",
        }}
        additionalMetaTags={[
          {
            thumbnailUrls: [finalImages[0]],
          },
        ]}
        additionalLinkTags={[
          {
            rel: "icon",
            href: "/images/logo/favicon.ico",
          },
        ]}
        facebook={{
          appId: "523038006675138",
        }}
        twitter={{
          handle: "@davinte_ai",
          site: "@davinte_ai",
          cardType: "summary_large_image",
        }}
      />
      <SocialProfileJsonLd
        type="Organization"
        name="Davinte.AI"
        url="http://www.davinte.ai"
        sameAs={[
          "http://www.facebook.com/davinteeai",
          "http://www.instagram.com/davinte_ai",
          "http://www.linkedin.com/company/davinte/",
        ]}
      />
      {subTitle && (
        <CollectionPageJsonLd
          name={`Davinte.AI | ${titleRefined}`}
          hasPart={[
            {
              about: desc,
              author: "Davinte.AI",
              datePublished: datePublised,
              audience: "Internet",
              keywords: subTitle,
              thumbnailUrl: finalImages[0],
              image: imageName,
            },
          ]}
        />
      )}
    </>
  );
};

// Meta.defaultProps = {
//   title: "Davinte.ai | Interior Design V2 with AI",
//   keyword:
//     "interior, chatgpt, artificial intelligence, crypto collectibles, crypto makretplace, cryptocurrency, digital items, market, nft, nft marketplace, nft next js, NFT react, non-fungible tokens, virtual asset, wallet",
//   desc: "A prioneer in AI for interior design.",
// };

export default Meta;

